<template>
  <div class="ProfileEdition" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Editer votre profil</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="CollaborateurUpdateAPIErrorModal" id="CollaborateurUpdateAPIErrorModal" hide-footer title="Erreur lors la mise à jour de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la modification de vos informations,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('CollaborateurUpdateAPIErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des informations de votre profil,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="CollaborateurUpdatedModal" id="CollaborateurUpdatedModal" hide-footer title="Votre profil a bien été mis à jour">
              <div class="text-center">
                <p><strong>Votre profil a bien été mis à jour</strong></p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('CollaborateurUpdatedModal')">Fermer</b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isProfileLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#F9BF3B">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form v-on:submit.prevent="updateCollaborateur()">
              <b-form-group id="modify-collaborateur-username-group" label="Votre email" label-for="modify-collaborateur-username-input" class="mandatory-input">
                <b-form-input
                  v-model="username" type="email"
                  maxlength="149"
                  placeholder="Email"
                  id="modify-collaborateur-username-input"
                  @input="$v.username.$touch()"
                  :state="$v.username.$dirty ? !$v.username.$error : null">
                </b-form-input>
                <small class="text-info"> Il s'agira de votre identifiant pour vous connecter à votre compte</small><br>
                <div class="form-error" v-if="!$v.username.email">Veuillez renseigner un format d'email valide.</div>
              </b-form-group>

              <div class="row">
                <div class="col-md-4">
                  <b-form-group id="modify-collaborateur-sex-group"
                    label="Vous êtes"
                    label-for="modify-collaborateur-sex-input"
                    class="mandatory-input">
                    <b-form-select
                      v-model="sex"
                      id="modify-collaborateur-sex-input"
                      @input="$v.sex.$touch()"
                      :state="$v.sex.$dirty ? !$v.sex.$error : null"
                      :options='genderOptions'>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-4">
                  <b-form-group id="modify-collaborateur-first_name-group" label="Prénom" label-for="modify-collaborateur-first_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="firstName" type="text"
                      maxlength="29"
                      placeholder="Prénom"
                      id="modify-collaborateur-first_name-input"
                      @input="$v.firstName.$touch()"
                      :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <b-form-group id="modify-collaborateur-last_name-group" label="Nom" label-for="modify-collaborateur-last_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="lastName" type="text"
                      maxlength="149"
                      placeholder="Nom"
                      id="modify-collaborateur-last_name-input"
                      @input="$v.lastName.$touch()"
                      :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <b-button
                pill block type="submit" variant="outline-dark" class="mt-2"
                :disabled="isInvalidBeneficiaire">
                   Modifier votre profil
              </b-button>
            </b-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import { APISettings } from '@/api/APISettings'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiSettings = new APISettings()

export default {
  name: 'ProfileEdition',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isProfileLoading: false,

      sex: 'H',
      firstName: '',
      lastName: '',
      username: '',

      genderOptions: [
        { text: 'Un homme', value: 'H' },
        { text: 'Une femme', value:  'F' },
      ],
      updateCollaborateurInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidBeneficiaire () {

      if (this.$v.firstName.$invalid || this.$v.lastName.$invalid || this.$v.username.$invalid) {
        return true
      }
      return false
    },
    isLoading () {
      if (this.isProfileLoading || this.updateCollaborateurInProcess) {
        return true
      }
      return false
    },
  },
  validations: {
    sex: {
      required
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    username: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
      email
    },
  },

  created: function() {
    this.getConnectedCollaborateur()
  },

  methods: {
    getConnectedCollaborateur () {
      this.isProfileLoading = true
      apiSettings.getConnectedCollaborateur(this.token)
        .then((result) => {

          this.sex = result.data.sex
          this.firstName = result.data.user.first_name
          this.lastName = result.data.user.last_name
          this.username = result.data.user.username
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('ProfileEdition getConnectedCollaborateur API Error : ' + String(error))
        })
        .finally(() => {
          this.isProfileLoading = false
        })
    },

    updateCollaborateur () {
      this.updateCollaborateurInProcess = true
      apiSettings.updateCollaborateurProfil(
        this.token, this.username, this.sex, this.firstName, this.lastName)
      .then(() => {
        this.$refs.CollaborateurUpdatedModal.show()
      })
      .catch((error) => {
        this.$refs.CollaborateurUpdateAPIErrorModal.show()
        throw new Error('ProfileEdition updateCollaborateur API Error : ' + String(error))
      })
      .finally(() => {
        this.updateCollaborateurInProcess = false
      })

    },
  }
}
</script>
